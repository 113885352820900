import { ScrollToExplore } from '@/components/scroll-to-explore/scroll-to-explore.tsx';
import { WaitlistCard } from '../waitlist-card/waitlist-card.tsx';
import { Brand } from './brand.tsx';
import { Header } from './header.tsx';
import { welcomeContainer } from './main-waitlist.css.ts';
import {
  cardBody,
  cardHeading,
  signUpBtn,
  signUpHelpText,
} from '../waitlist-card/waitlist-card.css.ts';
import { WaitlistForm } from '../waitlist-card/form.tsx';
import { Button } from '@synoptic/ui-kit/button/button.js';
import { useNavigate } from '@remix-run/react';
import { paths } from '@/routes-utils/paths.ts';

export const MainWaitlist = () => {
  const navigate = useNavigate();

  return (
    <div className={welcomeContainer}>
      <Header />
      <Brand>
        <ScrollToExplore />
      </Brand>
      <WaitlistCard>
        <h1 className={cardHeading}>Join the waitlist</h1>
        <p className={cardBody}>
          Stay tuned, we&apos;ll let you know as soon as Synoptic is available
        </p>
        <WaitlistForm />
        <Button
          onClick={() => navigate(paths.signup)}
          size="large"
          className={signUpBtn}
          variant="secondary"
        >
          Sign Up
        </Button>
        <p className={signUpHelpText}>If you already have an invite</p>
      </WaitlistCard>
    </div>
  );
};
